import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const aggregateImagesByDay = (images) => {
    if (images.length === 0) {
      return [];
    }
  
    // Calculate the min and max dates from the images array
    const dates = images.map(img => new Date(img.createdAt));
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));
  
    // Adjust minDate to the first day of the minDate's month and maxDate to the last day of the maxDate's month
    minDate.setDate(1);
    maxDate.setDate(new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0).getDate());
  
    const dayCounts = {};
    // Initialize all days in the range with 0
    for (let d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
      dayCounts[d.toISOString().split('T')[0]] = 0;
    }
  
    // Count each image upload per day
    images.forEach(image => {
      const date = new Date(image.createdAt).toISOString().split('T')[0];
      if (dayCounts.hasOwnProperty(date)) {
        dayCounts[date]++;
      }
    });
  
    // Convert the aggregated object back to an array suitable for charting
    return Object.keys(dayCounts).sort().map(date => ({
      x: date,
      y: dayCounts[date]
    }));
};

const MoodImageFrequencyGraph = ({ moodData = [], imageData = [] }) => {
  const aggregatedImageData = aggregateImagesByDay(imageData);

  const moodDataset = {
    label: 'Mood',
    data: moodData.map(entry => ({ x: entry.createdAt, y: entry.mood })),
    borderColor: 'rgb(54, 162, 235)',
    backgroundColor: 'rgba(54, 162, 235, 0.5)',
    yAxisID: 'y',
  };

  // Prepare data for image frequency
  const imageDataset = {
    label: 'Image Upload Frequency',
    data: aggregatedImageData,
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    yAxisID: 'y1',
  };

  const data = {
    datasets: [moodDataset, imageDataset],
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'week',
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Mood'
        },
        min: 0,
        max: 100
      },
      y1: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Image Uploads (Count)'
        },
        // Adjust the max based on the data for better visualization
        suggestedMax: 5
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      }
    },
  };

  return (
    <div style={{ minHeight: '400px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default MoodImageFrequencyGraph;